
import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { User } from '@/types'
import { CampaignApi, AddCampaignTipAndGenerateLotteryNumbersCommand } from '@/api'
import _ from 'lodash'
import { useServiceFunctions } from '@/composables/serviceFunctions'
import { UmbracoVariables } from '@/interfaces/umbracoVariables'

interface InputFields {
  name: InputField;
  email: InputField;
  colleagueName: InputField;
  colleagueEmail: InputField;
  approveCampaign: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  }
})

export default class Campaign extends mixins(State) {
  get currentYear (): number {
    const date = new Date(Date.now())
    return date.getFullYear()
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get campaignTermsAndConditions (): string {
    if (this.umbracoVariables) {
      return `Ik neem deel aan de "Bedrijfsfitness Startweken" campagne en ga akkoord met de <a href='${this.umbracoVariables.CampaignTermsAndConditionsUrl}'  target='_blank' rel='noopener noreferrer'>actievoorwaarden</a>.`
    }

    return 'Ik neem deel aan de "Bedrijfsfitness Startweken" campagne en ga akkoord met de actievoorwaarden'
  }

  emailPreviewHtml = ''
  formLoaded = false
  campaignRequestSuccess = false
  umbracoVariables: UmbracoVariables | undefined = undefined

  serviceFunctions = useServiceFunctions()

  inputFields: InputFields = {
    name: {
      name: 'name',
      value: this.currentUser.employeeDetails?.fullName || '',
      required: true,
      type: 'text',
      placeholder: 'Mijn naam',
      customErrorMessage: 'Oops, je bent je naam vergeten in te vullen',
      errors: [],
      inline: false
    },
    email: {
      name: 'email',
      value: this.currentUser.employeeDetails?.emailAddress || '',
      required: true,
      type: 'email',
      placeholder: 'Mijn e-mailadres',
      customErrorMessage: 'Oops, je bent je e-mailadres vergeten in te vullen',
      errors: [],
      inline: false
    },
    colleagueName: {
      name: 'colleagueName',
      value: '',
      required: true,
      type: 'text',
      placeholder: 'Naam collega',
      errors: [],
      inline: false
    },
    colleagueEmail: {
      name: 'colleagueEmail',
      value: '',
      required: true,
      type: 'text',
      placeholder: 'E-mailadres collega',
      errors: [],
      inline: false
    },
    approveCampaign: {
      name: 'approveCampaign',
      value: '',
      required: true,
      type: 'singleCheckbox',
      customErrorMessage: 'Oops, je bent nog niet akkoord gegaan met de campagne actievoorwaarden',
      placeholder: '',
      errors: [],
      options: [],
      inline: true
    }
  }

  private validateInput () {
    let valid = true

    valid = (this.$refs.name as FormInput).validate() && valid
    valid = (this.$refs.email as FormInput).validate() && valid
    valid = (this.$refs.colleagueName as FormInput).validate() && valid
    valid = (this.$refs.colleagueEmail as FormInput).validate() && valid
    // approveCampaign is no FormInput, so it is present in this.$refs.
    // Quick fix by just checking the input itself.
    // valid = (this.$refs.approveCampaign as FormInput).validate() && valid
    valid = (this.inputFields.approveCampaign.value + '') === 'true' && valid

    return valid
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  public inputChange () {
    this.getEmailPreviewDebounced()
  }

  public async getEmailPreview () {
    const user = this.currentUser

    const api = new CampaignApi()
    await api.campaignGetCampaignEmailPreview(user?.registration?.registrationId, this.inputFields.name.value, this.inputFields.colleagueName.value)
      .then(({ data }) => {
        this.emailPreviewHtml = data
        this.setState(this.states.COMPLETE)
      })
      .catch(() => {
        this.setState(this.states.ERROR)
      })
  }

  public getEmailPreviewDebounced = _.debounce(() => this.getEmailPreview(), 750)

  public async getUmbracoVariables () {
    const result = await this.serviceFunctions.getData(`${process.env.VUE_APP_UMBRACO_REGISTRATION_API}registration/getumbracovariables`)
    if (result.ok) {
      this.umbracoVariables = (await result.json()) as UmbracoVariables
    }
  }

  public async sendTip () {
    this.clearErrors()
    if (!this.validateInput()) {
      return
    }
    const command = {
      registrationId: this.currentUser?.registration?.registrationId,
      name: this.inputFields.name.value,
      email: this.inputFields.email.value,
      colleagueName: this.inputFields.colleagueName.value,
      colleagueEmail: this.inputFields.colleagueEmail.value,
      approveCampaign: this.inputFields.approveCampaign.value || false
    } as AddCampaignTipAndGenerateLotteryNumbersCommand

    const api = new CampaignApi()
    await api.campaignCreateCampaignTip(command)
      .then(() => {
        this.campaignRequestSuccess = true
      }).catch(() => {
        this.setState(this.states.ERROR)
      })
  }

  async mounted () {
    this.setState(this.states.LOADING)

    await this.getUmbracoVariables()
    await this.getEmailPreview()
  }
}
