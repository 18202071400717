export default class ServiceFunctions {
  getData (relativeUrl: string, querystringItems: Array<{ key: string; value: string }> | undefined = undefined) {
    let url = relativeUrl
    if (querystringItems && querystringItems.length) {
      for (let i = 0; i < querystringItems.length; i++) {
        url = this.addQuerystringItem(url, querystringItems[i].key, querystringItems[i].value)
      }
    }
    return fetch(url,
      {
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(async (result) => {
        return Promise.resolve(result)
      })
      .catch((error) => {
        return Promise.resolve(error)
      })
  }

  addQuerystringItem (url: string, key: string, value: string) {
    if (key && value) {
      if (!url.includes('?')) {
        url = `${url}?${key}=${value}`
      } else {
        url = `${url}&${key}=${value}`
      }
    }
    return url
  }
}

export function useServiceFunctions () {
  const serviceFunctions = new ServiceFunctions()
  return serviceFunctions
}
